import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IAxiosInstance, ICustomRequestConfig } from './request.config.type';

/**
 * 将 appConfig 的 request 配置项通过 axiosInstance.default 进行设置
 * @param requestConfig 配置项
 * @param axiosInstance 实例对象
 */
export function setAxiosInstance(requestConfig: ICustomRequestConfig, axiosInstance: IAxiosInstance) {
  const { interceptors = {}, ...requestOptions } = requestConfig;
  Object.keys(requestOptions).forEach((key) => {
    // @ts-ignore
    axiosInstance.defaults[key] = requestOptions[key];
  });

  // Add a request interceptor
  if (interceptors.request) {
    axiosInstance.interceptors.request.use(
      interceptors.request.onConfig ||
        ((config: AxiosRequestConfig) => {
          return config;
        }),
      interceptors.request.onError ||
        ((error: Error) => {
          return Promise.reject(error);
        }),
    );
  }

  // Add a response interceptor
  if (interceptors.response) {
    axiosInstance.interceptors.response.use(
      interceptors.response.onConfig ||
        ((response: AxiosResponse) => {
          return response;
        }),
      interceptors.response.onError ||
        ((error: Error) => {
          return Promise.reject(error);
        }),
    );
  }
}

export default setAxiosInstance;
