/**
 * index
 *
 * @author fukui
 */

import config from '@falla/config/src/config';
import appBridge from '@yg/app-bridge';

import { PagePluginsOptions } from '../types';
import aegisSdk from './aegis-sdk';
import { checkPlugin } from './check-plugin';
import PerformanceMonitor from './performance-monitor/index';
import { initChangeAccountPlugin } from './vconsole-change-account';

const init = (options: PagePluginsOptions) => {
  const packageInfo = options.package;
  // eslint-disable-next-line no-new
  const monitor = new PerformanceMonitor({
    initSuccessCallback({ instance, vConsole }) {
      initChangeAccountPlugin(vConsole, config.isDev);

      checkPlugin();
    },
  });

  const { env } = appBridge.getApiEnv();

  const isProd = env === 'prod' || config.isHostProd;

  if (!isProd) monitor.showVConsole(false);
  aegisSdk.init(packageInfo);
};

export default { init };
