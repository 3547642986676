import { getQueryParams } from '@falla/utils/src/common-util';
import { getProjectName } from '@falla/utils/src/stark-util';

import ErrorPlugin from './error';
import FirebasePlugin from './firebase';
import FlexiblePlugin from './flexible';
import I18nPlugin from './i18n';
import ReportPlugin from './report';
import { InitTypes, PagePluginsOptions } from './types';

const init = (options: PagePluginsOptions) => {
  window.__fromPackage__ = options.package.name || getProjectName();
  window.__translateId__ = Number(getQueryParams('translateId') || options?.package?.custom?.translateId || 0);

  // 避免重复初始化
  if (options.init.report && options.init.firebase) {
    options.init.firebase = false;
  }

  // eslint-disable-next-line guard-for-in
  for (const type in options.init) {
    // @ts-ignore
    const status = options.init[type];
    if (!status) continue;
    // eslint-disable-next-line default-case
    switch (type) {
      case InitTypes.flexible:
        FlexiblePlugin.init();
        break;
      case InitTypes.error:
        ErrorPlugin.init(options);
        break;
      case InitTypes.firebase:
      case InitTypes.report:
        // ReportPlugin.init(options);
        FirebasePlugin.init(options);

        // 避免未发布的活动调用到老代码报错问题。
        // @ts-ignore
        window.ta = { track: console.warn };
        break;
      case InitTypes.i18n:
        I18nPlugin.init(options);
        break;
    }
  }
};

export default { init, I18nPlugin, FirebasePlugin, ReportPlugin };
