// @ts-ignore
import { CustomPackageJson } from '@falla/page-plugins/src/types';
import urlParse from 'url-parse';

const urlQuery = urlParse(location.href, true).query || {};

enum AppEnvs {
  hk = 'hk',
  prod = 'prod',
}

const envList: String[] = Object.values(AppEnvs);

export const isDevEnv = (env?: string) => {
  return urlQuery.__env__ === 'local' || env === 'local' || process.env.NODE_ENV === 'development';
};

export const isLocalEnv = () => {
  return urlQuery.__env__ === 'local';
};

interface Params {
  packageInfo?: CustomPackageJson;
  projectName: String;
  version: String;
  env: string;
  isHostProd: boolean;
  location: {
    hostname?: string;
    port: number;
  };
}

const getDebugLocalPort = (port: number) => {
  return urlQuery.__port__ || port;
};

const getDebugLocalHost = () => {
  return urlQuery.__host__ || location.hostname || '127.0.0.1';
};

export function getBundleUrl({ projectName, env, location, isHostProd, version, packageInfo }: Params) {
  const fileVersion = `?t=${packageInfo?.shortCommitId}`;
  let jsUrl = `/${projectName}/${env}/${version}/js/index.js${fileVersion}`;
  let cssUrl = `/${projectName}/${env}/${version}/css/index.css${fileVersion}`;

  if (isDevEnv(env)) {
    jsUrl = `http://${getDebugLocalHost()}:${getDebugLocalPort(location.port)}/js/index.js${fileVersion}`;
    cssUrl = `http://${getDebugLocalHost()}:${getDebugLocalPort(location.port)}/css/index.css${fileVersion}`;
  }

  const result: string[] = [];
  if (cssUrl) result.push(cssUrl);
  if (jsUrl) result.push(jsUrl);
  return result;
}

export function getEntryUrl({ projectName, env, location }: Params) {
  if (isDevEnv(env)) return `http://${getDebugLocalHost()}:${getDebugLocalPort(location.port)}`;
  env = envList.includes(env) ? env : AppEnvs.hk;
  return `//web-test.falla.live/m/${projectName}/${env}/index.html`;
}

export const getProjectName = () => {
  const hashPath = location.hash.replace('#/', '');
  return hashPath.replace(/\?.*/, '').split('/')[0];
};
