export const checkPlugin = () => {
  urlQueryParamsCheck();
};

export const urlQueryParamsCheck = () => {
  // 检查服务端推送链接是否存在解码问题
  const isEncodeErr = window.location.href.includes('u0026');

  if (!isEncodeErr) return;

  const div = document.createElement('div');
  div.style.backgroundColor = 'red';
  div.style.position = 'fixed';
  div.style.top = '0';
  div.style.left = '0';
  div.style.zIndex = '9999999';
  div.innerHTML = '&nbsp; URL参数异常，请联系研发 &nbsp;';
  document.body.appendChild(div);
  console.error('URL参数异常，请联系研发');
};
