import createAxiosInstance from './createAxiosInstance';
import requestConfig from './request.config';
import setAxiosInstance from './setAxiosInstance';

const config = requestConfig[0];

const instanceName = 'default';
export const requestService = createAxiosInstance(instanceName);
setAxiosInstance(config, requestService);

export default requestService;
