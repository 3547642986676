import loadjs from '@falla/loadjs/src';

import { getParameter } from './utils';

declare global {
  interface Window {
    VConsole: any;
    vConsole: any;
  }
}

declare interface Log {
  type: string;
  source: string;
  message: string;
  meta: object | any;
}

declare interface Options {
  vConsoleCdn?: string;
  entry?: string;
  initSuccessCallback?: (opt: { vConsole: any; instance: PerformanceMonitor }) => void;
}

class PerformanceMonitor {
  private readonly options: Options = {
    vConsoleCdn: '//web-test.falla.live/npm/vconsole/3.14.6/dist/vconsole.min.js',
    entry: '',
  };
  private isInit = false;

  constructor(options: Options = {}) {
    this.options = Object.assign({}, this.options, options);
    this.init();
  }

  private init() {
    if (this.options.entry) {
      window.addEventListener('load', () => {
        this.setVConsoleEntryDebug(String(this.options.entry));
      });
    }

    const parameter = getParameter('__debug__');

    if (parameter) {
      if (parameter === 'true') {
        this.showVConsole(true);
      } else {
        this.showVConsole(false);
      }
    }
  }

  showVConsole(show: boolean): void {
    const triggerShowVConsole = () => {
      if (!show) return;
      try {
        window.vConsole.show();
      } catch (e) {
        console.log('e');
      }
    };
    if (this.isInit) return triggerShowVConsole();
    if (!this.options.vConsoleCdn) return;
    loadjs(this.options.vConsoleCdn, () => {
      // support vconsole3.0
      if (typeof window.vConsole === 'undefined') {
        window.vConsole = new window.VConsole({
          defaultPlugins: ['system', 'network', 'element', 'storage'],
          maxLogNumber: 5000,
        });

        this.options.initSuccessCallback?.({
          vConsole: window.vConsole,
          instance: this,
        });
      }
      this.isInit = true;

      if (!show) return;
      triggerShowVConsole();
      window.addEventListener('load', () => {
        window.vConsole.show();
      });
    });
  }

  setVConsoleEntryDebug(selector: string): void {
    if (!selector) return;
    let count = 0;
    const entry = document.querySelector(selector);
    if (!entry) return;
    entry.addEventListener('click', () => {
      count++;
      if (count < 10) return;
      count = -10000;
      this.showVConsole(true);
    });
  }
}

export default PerformanceMonitor;
