import axios from 'axios';

import { IAxiosInstance } from './request.config.type';

const DEFAULT_CONFIG = {};

const axiosInstances: { [key: string]: IAxiosInstance } = {};

/**
 * 创建 Axios 实例
 * @param instanceName 实例名称
 */
export function createAxiosInstance(instanceName = 'default'): IAxiosInstance {
  if (axiosInstances[instanceName]) return axiosInstances[instanceName];
  // @ts-ignore
  axiosInstances[instanceName] = axios.create(DEFAULT_CONFIG);
  return axiosInstances[instanceName];
}

export default createAxiosInstance;
