/**
 * i18n
 *
 * @author fukui
 */
import { NameI18N } from '@components/activity/src/components/RewardGroup/types';

import { getQueryParams, getWebHostProdRelativePath } from './common-util';
import { combineURLs } from './string-util';

const getLang = () => {
  const defaultLang = navigator.language.toLowerCase();
  return defaultLang;
};

interface IGetLanguage {
  defaultLang?: string;
  includesLang?: string[];
}

export const getLanguage = (opt?: IGetLanguage) => {
  const language = getQueryParams('debugI18n') || getLang();
  const locales = opt?.includesLang || getLocales();
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) return locale;
  }
  return opt?.defaultLang || 'en';
};

export const getLocales = () => {
  return ['en', 'zh', 'ar', 'tr', 'es', 'bn', 'hi', 'pt', 'ur', 'id', 'ms'];
};

export const getI18nTranslationUrl = (translateId: number | string, isHostProd: boolean, isDev: boolean) => {
  return combineURLs(
    `${getWebHostProdRelativePath(isDev)}/lang_conf/`,
    `${isHostProd ? '' : 'pre'}/h5_${translateId}.json`,
  );
};

// 中文
// 英语
// 阿语 => 英语
// 土语 => 英语
// 西语 => 英语
// 葡萄牙语 => 西语 => 英语
// 印地语 => 英语
// 孟加拉语 => 英语
// 乌尔都语 => 英语
// id 印尼 => 英语
// ms 马来语 => 英语
// 兜底语言
export const langMap: {
  [key in string]: string[];
} = {
  zh: ['en'],
  en: ['en'],
  ar: ['en'],
  tr: ['en'],
  es: ['en'],
  pt: ['en'],
  bn: ['en'],
  hi: ['en'],
  ur: ['en'],
  id: ['en'],
  ms: ['en'],
};

// 兜底语言策略实现
export const getI18nText = (nameI18n: NameI18N, lang: keyof NameI18N | string): string => {
  if (!nameI18n) return '';

  if (nameI18n[lang as keyof NameI18N]) return nameI18n[lang as keyof NameI18N];

  const langs = langMap[lang] || [];

  if (langs.length === 0) return nameI18n.en;

  const key = langs.find((v) => nameI18n?.[v as keyof NameI18N]);

  return nameI18n?.[(key as keyof NameI18N) || 'en'];
};
