/**
 * file-util
 *
 * @author fukui
 */
export const getSuffix = function (filename: string): string {
  const pos: number = filename.lastIndexOf('.');
  let suffix = '';
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};

export const isImageSuffix = function (fileSuffix: string): boolean {
  const reg = /(\.jpg|\.png|\.gif|\.jpeg|\.webp)$/;
  return reg.test(fileSuffix);
};

export const isVideoSuffix = function (fileSuffix: string): boolean {
  const reg = /(\.mp4)$/;
  return reg.test(fileSuffix);
};

export const isWebpSuffix = function (fileSuffix: string): boolean {
  const reg = /(\.webp)$/;
  return reg.test(fileSuffix);
};

export const isJsonSuffix = function (fileSuffix: string): boolean {
  const reg = /(\.json)$/;
  return reg.test(fileSuffix);
};

export const isSupportWebp: boolean = (function (): boolean {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } catch (err) {
    return false;
  }
})();

export const convertLocalFileToFileProtocol = function (filePath: string): string {
  if (!filePath) return filePath;
  return `file:///${filePath.replace(/\\/g, '/')}`;
};
